import React from 'react';
import styled from 'styled-components';
import { atMinWidth } from '../../styles/atoms/breakpoints';
import { Card } from '../../styles/Components/TableofContent';
import TableItem from './TableItem';

const TableOfContents = ({ component }) =>
  component && component.length > 0 ? (
    <TocCard className="table-of-contents">
      <h4>Table of Contents</h4>
      <ul className="table-items">
        {component && component.map(item => (
          <TableItem key={item?.label} item={item} />
        ))}
      </ul>
    </TocCard>
  ) : null;

export const TocCard = styled(Card)`
  padding: 0;

  h4,
  .table-item p {
    padding-left: 0;

    ${atMinWidth.lg`
      padding-left: 8px;
    `}
  }

  .title-link {
    &.level-2 {
      padding-left: 18px;
    }

    &.level-3 {
      padding-left: 28px;
    }

    &.level-4 {
      padding-left: 38px;
    }

    &.level-5 {
      padding-left: 48px;
    }
  }
`;

export default TableOfContents;
